/**
  @module ember-data
*/

/**
  All Ember Data methods and functions are defined inside of this namespace.

  @class DS
  @static
*/

/**
  @property VERSION
  @type String
  @default '1.13.12'
  @static
*/
/*jshint -W079 */
var DS = Ember.Namespace.create({
  VERSION: '1.13.12'
});

if (Ember.libraries) {
  Ember.libraries.registerCoreLibrary('Ember Data', DS.VERSION);
}

var EMBER_DATA_FEATURES = {}; //jshint ignore: line

Ember.merge(Ember.FEATURES, EMBER_DATA_FEATURES);

export default DS;
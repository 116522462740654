define('ember-modal-dialog/templates/components/tether-dialog', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 7,
                "column": 2
              }
            },
            "moduleName": "modules/ember-modal-dialog/templates/components/tether-dialog.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["inline","modal-dialog-overlay",[],["action","close","class",["subexpr","concat",[["subexpr","if",[["get","overlayClassNamesString",[]],["subexpr","-normalize-class",["overlayClassNamesString",["get","overlayClassNamesString",[]]],[],[]]],[],[]]," ",["subexpr","if",[["get","translucentOverlay",[]],"translucent"],[],[]]," ",["subexpr","if",[["get","overlay-class",[]],["subexpr","-normalize-class",["overlay-class",["get","overlay-class",[]]],[],[]]],[],[]]," "],[],[]]],["loc",[null,[3,4],[6,6]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "modules/ember-modal-dialog/templates/components/tether-dialog.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","if",[["get","hasOverlay",["loc",[null,[2,8],[2,18]]]]],[],0,null,["loc",[null,[2,2],[7,9]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "modules/ember-modal-dialog/templates/components/tether-dialog.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","yield",["loc",[null,[14,4],[14,13]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 16,
              "column": 0
            }
          },
          "moduleName": "modules/ember-modal-dialog/templates/components/tether-dialog.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","ember-modal-dialog-positioned-container",[],["targetAttachment",["subexpr","@mut",[["get","targetAttachment",["loc",[null,[11,23],[11,39]]]]],[],[]],"target",["subexpr","@mut",[["get","target",["loc",[null,[12,13],[12,19]]]]],[],[]],"class",["subexpr","concat",[["subexpr","if",[["get","containerClassNamesString",[]],["subexpr","-normalize-class",["containerClassNamesString",["get","containerClassNamesString",[]]],[],[]]],[],[]]," ",["subexpr","if",[["get","targetAttachmentClass",[]],["subexpr","-normalize-class",["targetAttachmentClass",["get","targetAttachmentClass",[]]],[],[]]],[],[]]," ",["subexpr","if",[["get","container-class",[]],["subexpr","-normalize-class",["container-class",["get","container-class",[]]],[],[]]],[],[]]," "],[],[]]],0,null,["loc",[null,[10,2],[15,46]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child2 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.10",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 27,
                "column": 2
              }
            },
            "moduleName": "modules/ember-modal-dialog/templates/components/tether-dialog.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
            return morphs;
          },
          statements: [
            ["content","yield",["loc",[null,[26,4],[26,13]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.10",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "modules/ember-modal-dialog/templates/components/tether-dialog.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [
          ["block","ember-tether",[],["target",["subexpr","@mut",[["get","target",["loc",[null,[18,13],[18,19]]]]],[],[]],"attachment",["subexpr","@mut",[["get","attachment",["loc",[null,[19,17],[19,27]]]]],[],[]],"targetAttachment",["subexpr","@mut",[["get","targetAttachment",["loc",[null,[20,23],[20,39]]]]],[],[]],"targetModifier",["subexpr","@mut",[["get","targetModifier",["loc",[null,[21,21],[21,35]]]]],[],[]],"classPrefix",["subexpr","@mut",[["get","tetherClassPrefix",["loc",[null,[22,18],[22,35]]]]],[],[]],"offset",["subexpr","@mut",[["get","offset",["loc",[null,[23,13],[23,19]]]]],[],[]],"targetOffset",["subexpr","@mut",[["get","targetOffset",["loc",[null,[24,19],[24,31]]]]],[],[]],"class",["subexpr","concat",[["subexpr","if",[["get","containerClassNamesString",[]],["subexpr","-normalize-class",["containerClassNamesString",["get","containerClassNamesString",[]]],[],[]]],[],[]]," ",["subexpr","if",[["get","container-class",[]],["subexpr","-normalize-class",["container-class",["get","container-class",[]]],[],[]]],[],[]]," "],[],[]]],0,null,["loc",[null,[17,2],[27,19]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "modules/ember-modal-dialog/templates/components/tether-dialog.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        morphs[1] = dom.createMorphAt(fragment,1,1,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","ember-wormhole",[],["to",["subexpr","@mut",[["get","destinationElementId",["loc",[null,[1,21],[1,41]]]]],[],[]],"renderInPlace",["subexpr","@mut",[["get","renderInPlace",["loc",[null,[1,56],[1,69]]]]],[],[]]],0,null,["loc",[null,[1,0],[8,19]]]],
        ["block","if",[["get","renderInPlace",["loc",[null,[9,6],[9,19]]]]],[],1,2,["loc",[null,[9,0],[28,7]]]]
      ],
      locals: [],
      templates: [child0, child1, child2]
    };
  }()));

});
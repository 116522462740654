/* jshint ignore:start */

window.EmberENV = {"FEATURES":{}};
var runningTests = false;

window.deprecationWorkflow = window.deprecationWorkflow || {};
window.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchMessage: 'Ember.keys is deprecated in favor of Object.keys' },
    { handler: 'silence', matchMessage: 'Ember.ContainerView is deprecated.' },
    { handler: 'silence', matchMessage: 'Using the same function as getter and setter is deprecated.' },
    { handler: 'silence', matchMessage: 'Using a custom `.render` function is deprecated and will be removed in Ember 2.0.0.' },
    { handler: 'silence', matchMessage: 'DS.FixtureAdapter has been deprecated and moved into an unsupported addon: https://github.com/emberjs/ember-data-fixture-adapter/tree/master' },
    { handler: 'silence', matchMessage: 'You tried to look up \'store:main\', but this has been deprecated in favor of \'service:store\'.' },
    { handler: 'silence', matchMessage: '`lookup` was called on a Registry. The `initializer` API no longer receives a container, and you should use an `instanceInitializer` to look up objects from the container.' },
    { handler: 'silence', matchMessage: 'In Ember 2.0 service factories must have an `isServiceFactory` property set to true. You registered (unknown mixin) as a service factory. Either add the `isServiceFactory` property to this factory or extend from Ember.Service.' }
  ]
};


/* jshint ignore:end */

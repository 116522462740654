define('torii/lib/load-initializer', ['exports'], function (exports) {

  'use strict';

  /* global Ember */
  exports['default'] = function (initializer) {
    Ember.onLoad('Ember.Application', function (Application) {
      Application.initializer(initializer);
    });
  }

});